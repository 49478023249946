<template>
  <v-fab
    v-show="showFAB"
    id="devtools-warnings-fab"
    icon
    color="warning"
    :style="{
      right: showPanel ? '370px' : '70px',
    }"
    @click="togglePanel"
  >
    <v-badge color="error" :content="warnings.length">
      <v-icon :icon="showPanel ? 'mdi-arrow-right-bold' : 'mdi-alert'" />
    </v-badge>
  </v-fab>
  <v-card
    id="devtools-warnings-panel"
    width="300"
    color="warning"
    :style="{
      transform: showPanel ? 'translateX(0)' : 'translateX(100%)',
    }"
  >
    <v-card-title>Schema Warnings</v-card-title>
    <v-card-text>
      <div
        v-for="warning of warnings"
        :key="warning.id"
        class="my-2"
      >
        <v-row
          align="center"
          @click="toggleRow(warning.id)"
        >
          <v-col cols="11">
            <p class="font-weight-bold">
              <span class="bg-red mr-1" v-text="warning.type.toUpperCase()" />
              <span v-text="warning.title" />
            </p>
            <p v-if="warning.description" v-text="warning.description" />
          </v-col>
          <v-col cols="1">
            <v-btn
              v-if="warning.context"
              size="sm"
              variant="text"
              :icon="rows[warning.id] ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            />
          </v-col>
        </v-row>
        <code v-if="warning.context && rows[warning.id]" class="block bg-gray-600 w-100% mt-2 pa-2 text-white" v-text="warning.context" />
        <v-divider class="mt-2" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { computed, defineComponent, inject, reactive, ref, watch } from 'vue'

import { CONTROLLER_KEY } from '@/plugins/devtools/shared'

export default defineComponent({
  name: 'DevtoolsWarnings',

  setup () {
    const controller = inject(CONTROLLER_KEY)!
    const warnings = computed(() => controller.currentConfigWarnings ?? [])

    const showFAB = computed(() => warnings.value.length > 0)
    watch(showFAB, () => {
      showPanel.value = false
    })

    const showPanel = ref(false)
    function togglePanel (): void {
      showPanel.value = !showPanel.value
    }

    const rows = reactive<Record<string, boolean>>({})
    function toggleRow (id: string): void {
      rows[id] = !rows[id]
    }

    return {
      warnings,
      showFAB,
      showPanel,
      togglePanel,
      rows,
      toggleRow,
    }
  },
})
</script>

<style lang="sass" scoped>
#devtools-warnings-fab
  position: fixed
  bottom: 150px
  right: 70px
  z-index: 1000
#devtools-warnings-panel
  position: fixed
  bottom: 0
  top: 115px
  right: 0
  z-index: 1000
  // matches vuetify
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1)
</style>
