import debounce from 'lodash-es/debounce'

import { InspectorRootNodes, noDataTag } from '@/plugins/devtools/shared'

import type Payload from '@/lib/Payload'
import type { Devtools } from '@/plugins/devtools/api'
import type { CustomInspectorNode } from '@vue/devtools-kit'
import type { DisplaySection, UniversalFormSchema } from 'types/form'
import type { ShallowRef } from 'vue'

declare module '@/plugins/devtools/api' {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  interface Devtools {
    /**
     * Loads form data into the devtools for inspection.
     */
    loadForm(details: LoadedFormDetails): void
    /**
     * Triggers a form update causing the inspector to refresh.
     */
    triggerFormUpdate(): void
    /**
     * Unloads form data from the devtools.
     */
    unloadForm(): void
  }
}

interface LoadedFormDetails {
  mainPayload: ShallowRef<Payload>
  multiPayloads: ShallowRef<Payload[]>
  groupPayloads: ShallowRef<Payload[]>
  displaySections: ShallowRef<DisplaySection<UniversalFormSchema>[]>
}
interface LoadedForm {
  details: LoadedFormDetails | null
}

export function setupFormNode (devtools: Devtools): void {
  const rootNodeTitle = 'Form Data'
  const loadedForm: LoadedForm = {
    details: null,
  }
  const payloadsNode: CustomInspectorNode = {
    id: devtools.buildNodeId(InspectorRootNodes.FORM),
    label: 'Payloads',
  }
  const sectionsNode: CustomInspectorNode = {
    id: devtools.buildNodeId(InspectorRootNodes.FORM),
    label: 'Sections',
  }

  devtools.loadForm = function (details) {
    loadedForm.details = details
    devtools.refresh()
  }
  devtools.triggerFormUpdate = debounce(function () {
    devtools.refresh()
  }, 500)
  devtools.unloadForm = function () {
    loadedForm.details = null
    this.currentConfigWarnings = []
    devtools.refresh()
  }

  devtools.rootNodeGetters.push((payload) => {
    if (payload.filter && !rootNodeTitle.toLowerCase().includes(payload.filter.toLowerCase())) {
      return null
    }

    const node: Required<CustomInspectorNode> = {
      id: InspectorRootNodes.FORM,
      label: rootNodeTitle,
      children: [],
      tags: [],
      name: '',
      file: '',
    }
    if (!loadedForm.details) {
      node.tags.push(noDataTag)
    } else {
      node.children.push(
        payloadsNode,
        sectionsNode,
      )
    }
    return node
  })

  devtools.stateGetters.push((payload) => {
    if (devtools.getRootNode(payload.nodeId) !== InspectorRootNodes.FORM) return
    if (!loadedForm.details) return

    if (payload.nodeId === payloadsNode.id) {
      payload.state = {
        mainPayload: [{
          key: 'data',
          editable: true,
          value: loadedForm.details.mainPayload.value.payloadObject,
        }],
        multiPayloads: loadedForm.details.multiPayloads.value.map((multiPayload, index) => {
          return {
            key: `multi${index}`,
            editable: true,
            value: multiPayload.payloadObject,
          }
        }),
        groupPayloads: loadedForm.details.groupPayloads.value.map((groupPayload, index) => {
          return {
            key: `group${index}`,
            editable: true,
            value: groupPayload.payloadObject,
          }
        }),
      }
    } else if (payload.nodeId === sectionsNode.id) {
      payload.state = {
        sections: loadedForm.details.displaySections.value.map((section) => {
          return {
            key: section.label,
            editable: false,
            value: section,
          }
        }),
      }
    }
  })

  devtools.stateEditors.push((payload) => {
    if (devtools.getRootNode(payload.nodeId) !== InspectorRootNodes.FORM) return
    if (payload.nodeId === payloadsNode.id) {
      switch (payload.type) {
        case 'mainPayload':
        case 'multiPayloads':
        case 'groupPayloads': {
          if (payload.state.remove) {
            devtools.logger.warn('Not able to remove values, only edit')
            return
          }
          const updateValue = (() => {
            switch (payload.type) {
              case 'mainPayload': return loadedForm.details?.mainPayload.value.payloadObject
              case 'multiPayloads': {
                const index = +payload.path[0].replace('multi', '')
                return loadedForm.details?.multiPayloads.value[index]?.payloadObject
              }
              case 'groupPayloads': {
                const index = +payload.path[0].replace('group', '')
                return loadedForm.details?.groupPayloads.value[index]?.payloadObject
              }
            }
          })()
          if (!updateValue) {
            devtools.logger.warn('Could not find value to update')
            return
          }
          payload.set(
            updateValue,
            payload.path.slice(1), // remove prefix (eg. 'data' or 'multi0')
            payload.state.value,
          )
        }
      }
    } else {
      devtools.logger.warn('Editing for that value is not supported')
    }
  })
}
