<template>
  <div>
    <v-list-item @click="showUploadDialog">
      <template #prepend>
        <v-icon icon="mdi-file-upload" />
      </template>
      <v-list-item-title>Upload Policy</v-list-item-title>
    </v-list-item>
    <v-dialog v-model="uploadDialog" width="380">
      <v-card>
        <v-card-title>Upload Policy</v-card-title>
        <v-card-text class="my-2">
          <p>Please select a <strong>.yaml</strong> file containing a valid PolicyState.</p>
          <p>It will be saved in your local database.</p>
          <v-file-input
            v-model="file"
            truncate-length="45"
            accept=".yaml"
          />
        </v-card-text>
        <v-card-actions class="px-4 pb-4">
          <v-row justify="space-between" no-gutters>
            <v-col cols="auto">
              <v-btn
                theme="dark"
                color="red"
                :disabled="isLoading.value"
                :loading="isLoading.name === 'reset'"
                @click="onCancel"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                theme="dark"
                color="green"
                :disabled="isLoading.value || !file"
                :loading="isLoading.name === 'confirm'"
                @click="onUpload"
              >
                Upload
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { ApplicationServiceClient, PolicyState } from '@policyfly/protobuf'
import yaml from 'js-yaml'
import { defineComponent, ref } from 'vue'

import { useLoading } from '@/composables/loading'
import { useNotification } from '@/composables/notification'
import { useApiStore } from '@/stores/api'

import { devtools } from '@/plugins/devtools/api'

export default defineComponent({
  name: 'DevtoolsActionUpload',

  setup () {
    const apiStore = useApiStore()
    const applicationServiceClient = new ApplicationServiceClient(apiStore.transport!)
    const { isLoading } = useLoading()

    const file = ref<File[] | undefined>()
    const uploadDialog = ref(false)

    function showUploadDialog (): void {
      uploadDialog.value = true
    }

    async function onCancel (): Promise<void> {
      uploadDialog.value = false
    }

    const { snackbar } = useNotification()

    async function onUpload (): Promise<void> {
      try {
        const reader = new FileReader()

        // @ts-expect-error: Might need to convert to Blob
        reader.readAsText(file.value)
        reader.onload = async () => importPolicy(reader)

        uploadDialog.value = false
      } catch (err) {
        file.value = undefined
        console.error(err)
        snackbar.error('Failed to upload Policy, check console for more information')
      }
    }

    async function importPolicy (reader: FileReader): Promise<void> {
      try {
        const canonicalJson = yaml.load(reader.result as string)
        // @ts-expect-error: this should be policy json
        const request = PolicyState.fromJson(canonicalJson)
        request.id = 0
        request.uuid = ''
        const { response } = await applicationServiceClient.newApplication(request)

        devtools.logGrpc({
          description: 'Import Application',
          messages: [
            { type: PolicyState, key: 'request', message: request },
            { type: PolicyState, key: 'response', message: response },
          ],
        })

        snackbar.success(`Policy "${request.primaryNamedInsured?.legalName}" was uploaded successfully`)
      } catch (err) {
        reader.abort()
        console.error(err)
        snackbar.error('Unable to import Policy, check console for more information')
      }

      file.value = undefined
    }

    return {
      uploadDialog,
      showUploadDialog,
      file,
      isLoading,
      onCancel,
      onUpload,
    }
  },
})
</script>
