import {
  DeleteDeviceRequest,
  MultipartFormFile,
  UpdateAccountRequest,
} from '@policyfly/protobuf/patrick'
import { defineStore } from 'pinia'

import { useApiStore } from '@/stores/api'
import { useAuthenticationStore } from '@/stores/authentication'

import { hotReloadStore } from '@/utils/build'

import type { AccountResponse, ProvisionDeviceResponse_Totp } from '@policyfly/protobuf/patrick'

export interface State extends
  Pick<AccountResponse,
    | 'id'
    | 'avatar'
    | 'firstName'
    | 'lastName'
    | 'email'
  > {
  smsDeviceNumber: string | null | undefined
  provisionedNumber: string
  authenticatorUrl: string | null | undefined
  authUpdated: boolean
}

export const useUserStore = defineStore({
  id: 'user',

  state: (): State => ({
    id: 0,
    avatar: '',
    firstName: '',
    lastName: '',
    email: '',
    smsDeviceNumber: '',
    provisionedNumber: '',
    authenticatorUrl: '',
    authUpdated: false,
  }),

  getters: {
    loaded: (state): boolean => !!state.firstName,
    details: (state): Pick<State, 'id' | 'avatar' | 'firstName' | 'lastName' | 'email'> => state,
    hasAuthenticator: (state): boolean => !!state.authenticatorUrl,
    hasSMS: (state): boolean => !!state.smsDeviceNumber,
    fullName: (state): string => `${state.firstName} ${state.lastName}`,
  },

  actions: {
    async load (): Promise<void> {
      const apiStore = useApiStore()
      const authenticationStore = useAuthenticationStore()
      const response = await apiStore.user.account({
        bingoId: authenticationStore.bingoId ?? '',
      })
      this.id = response.id
      authenticationStore.userId = response.id ?? null
      this.avatar = response.avatar ?? ''
      this.firstName = response.firstName
      this.lastName = response.lastName
      this.email = response.email
      authenticationStore.programs = response.programs
      authenticationStore.agency = response.agency ?? null

      this.smsDeviceNumber = response.device?.phoneNumber ?? null
      this.authenticatorUrl = response.device?.qrCode ?? null
    },
    async editDetails (
      data: Pick<UpdateAccountRequest, 'firstName' | 'lastName' | 'email'> & { photo: File | null },
    ): Promise<void> {
      const apiStore = useApiStore()
      const request = UpdateAccountRequest.create({
        id: this.id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
      })
      if (data.photo) {
        request.avatar = MultipartFormFile.create({
          name: data.photo.name,
          mimeType: data.photo.type,
          content: new Uint8Array(await data.photo.arrayBuffer()),
        })
      }
      const res = await apiStore.user.updateAccount(request)
      if (data.photo && res.avatarUrl) this.avatar = res.avatarUrl
      this.firstName = res.firstName
      this.lastName = res.lastName
      this.email = res.email
    },
    async provisionSMS (number?: string): Promise<void> {
      const apiStore = useApiStore()
      if (number) this.provisionedNumber = number
      await apiStore.user.provisionDevice({
        emailAddress: this.email,
        method: { oneofKind: 'phone', phone: { phoneNumber: this.provisionedNumber } },
      })
    },
    async updateSMS (token: string): Promise<void> {
      const apiStore = useApiStore()
      const response = await apiStore.user.updateDevice({
        emailAddress: this.email,
        token,
        method: { oneofKind: 'phone', phone: {} },
      })
      if (response.method.oneofKind !== 'phone') {
        throw new Error('Unexpected response from server')
      }
      this.smsDeviceNumber = String(response.method.phone.phoneNumber)
      this.provisionedNumber = ''
      this.authUpdated = true
    },
    async clear2fa (device: 'totp' | 'sms'): Promise<void> {
      if (!['totp', 'sms'].includes(device)) {
        throw new Error(`Device type ${device} not allowed.`)
      }
      const apiStore = useApiStore()
      await apiStore.user.deleteDevice(DeleteDeviceRequest.create({
        method: device === 'totp'
          ? { oneofKind: 'totp', totp: {} }
          : { oneofKind: 'phone', phone: {} },
      }))
      if (device === 'totp') this.authenticatorUrl = ''
      if (device === 'sms') this.smsDeviceNumber = ''
    },
    async provisionQR (): Promise<ProvisionDeviceResponse_Totp> {
      const apiStore = useApiStore()
      const response = await apiStore.user.provisionDevice({
        emailAddress: this.email,
        method: { oneofKind: 'totp', totp: {} },
      })
      if (response.method.oneofKind !== 'totp') {
        throw new Error('Unexpected response from server')
      }
      return response.method.totp
    },
    async updateQR (token: string): Promise<void> {
      const apiStore = useApiStore()
      await apiStore.user.updateDevice({
        emailAddress: this.email,
        token,
        method: { oneofKind: 'totp', totp: {} },
      })
      // TODO: if this does end up being displayed somewhere we'll have to hit the devices endpoint to retrieve it again
      // as for now we just need some value
      this.authenticatorUrl = 'updated'
      this.authUpdated = true
    },
    /**
     * Attempts to log the user out of the application and invalidate the session with the API.
     * Removes all tokens and authentication data from stores.
     */
    async logout (): Promise<void> {
      try {
        const apiStore = useApiStore()
        await apiStore.user.logout()
      } catch (err) {
        console.error(`Error invalidating session with API. Clearing local data. ${err}`)
      } finally {
        this.$reset()
        const authenticationStore = useAuthenticationStore()
        authenticationStore.$reset()
      }
    },
  },
})

hotReloadStore(useUserStore)
