import { generateUUID } from '@policyfly/utils/uuid'
import { setupDevtoolsPlugin } from '@vue/devtools-api'
import { createApp, reactive } from 'vue'

import DevtoolsApp from '@/plugins/devtools/app/DevtoolsApp.vue'

import { devtools } from '@/plugins/devtools/api'
import { setupApplicationNode } from '@/plugins/devtools/nodes/application'
import { setupFormNode } from '@/plugins/devtools/nodes/form'
import { setupGrpcNode } from '@/plugins/devtools/nodes/grpc'
import { setupLuaNode } from '@/plugins/devtools/nodes/lua'
import { setupSessionNode } from '@/plugins/devtools/nodes/session'
import { setupPinia } from '@/plugins/devtools/pinia'
import { CONTROLLER_KEY } from '@/plugins/devtools/shared'
import { pinia } from '@/plugins/pinia'
import { vuetify } from '@/plugins/vuetify'

import type { DevtoolsController, InspectorRootNodes } from '@/plugins/devtools/shared'
import type { CustomInspectorNode } from '@vue/devtools-kit'
import type { App, Plugin } from 'vue'

const INSPECTOR_ID = 'policyfly'

function createDevtoolsInstance (app: App): void {
  setupDevtoolsPlugin({
    id: 'com.policyfly.devtools',
    label: 'PolicyFly',
    logo: 'https://app.policyfly.com/logo.png',
    homepage: 'https://app.policyfly.com',
    app,
    settings: {
      showFAB: {
        label: 'Show the Devtools FAB',
        type: 'boolean',
        defaultValue: false,
      },
      luaLogLimit: {
        label: 'Limits lua logs fetched',
        type: 'text',
        defaultValue: '10',
      },
      grpcLogLimit: {
        label: 'Limits grpc calls stored',
        type: 'text',
        defaultValue: '10',
      },
      grpcLogLevel: {
        label: 'Level of grpc calls stored',
        type: 'choice',
        defaultValue: 'info',
        options: [
          { label: 'Debug (All)', value: 'debug' } as const,
          { label: 'Info', value: 'info' } as const,
        ],
      },
    },
  }, (api) => {
    devtools.api = api

    // add custom inspector
    api.addInspector({
      id: INSPECTOR_ID,
      label: 'PolicyFly',
      icon: 'description',
      treeFilterPlaceholder: 'Search PolicyFly',
      stateFilterPlaceholder: 'Search data',
      noSelectionText: 'Nothing selected',
      nodeActions: devtools.rootNodeActions,
    })
    api.on.getInspectorTree((payload) => {
      if (payload.inspectorId !== INSPECTOR_ID) return

      payload.rootNodes = devtools.rootNodeGetters.reduce<CustomInspectorNode[]>((acc, generator) => {
        const node = generator(payload)
        if (node) acc.push(node)
        return acc
      }, [])
    })
    api.on.getInspectorState((payload) => {
      if (payload.inspectorId !== INSPECTOR_ID) return

      for (const generator of devtools.stateGetters) {
        generator(payload)
      }
    })
    api.on.editInspectorState((payload) => {
      if (payload.inspectorId !== INSPECTOR_ID) return

      for (const editor of devtools.stateEditors) {
        editor(payload)
      }
    })

    api.on.setPluginSettings(() => {
      devtools.refresh()
    })
  })
}

/**
 * Registers and loads the devtools plugin.
 * Also creates all the devtools api functionality that can be used externally.
 *
 * This is only run once on app creation if devtools are set to be installed.
 */
export function setupDevtools (app: App): void {
  // separate devtools Vue instance
  const controller: DevtoolsController = reactive({
    confirm: null,
    pluginSettings: {
      showFAB: false,
      luaLogLimit: '10',
      grpcLogLimit: '10',
      grpcLogLevel: 'info',
    },
    currentConfigWarnings: [],
  })
  const devtoolsEl = document.createElement('div')
  document.body.appendChild(devtoolsEl)
  const devtoolsApp = createApp(DevtoolsApp)
  devtoolsApp.use(vuetify)
  devtoolsApp.provide(CONTROLLER_KEY, controller)
  devtoolsApp.use(pinia)
  devtoolsApp.mount(devtoolsEl)
  devtools.app = devtoolsApp

  // shared utils
  devtools.prettyTime = function (ms?: number) {
    return (ms ? new Date(ms) : new Date())
      .toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        fractionalSecondDigits: 3,
      })
  }
  devtools.prettyDate = function (ms?: number) {
    return (ms ? new Date(ms) : new Date())
      .toLocaleTimeString('en-GB', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
  }
  devtools.buildNodeId = function (root: InspectorRootNodes) {
    return `${root}:${generateUUID()}`
  }
  devtools.getRootNode = function (nodeId) {
    return nodeId.split(':')[0] as InspectorRootNodes ?? null
  }
  function log (type: 'log' | 'warn' | 'error', message: string): void {
    const fullMessage = `[PolicyFly Devtools] ${message}`
    // eslint-disable-next-line no-console
    console[type](fullMessage)
  }
  devtools.logger.warn = log.bind(null, 'warn')
  devtools.logger.error = log.bind(null, 'error')
  devtools.refresh = function () {
    controller.currentConfigWarnings = [...this.currentConfigWarnings]
    if (!this.api) return
    controller.pluginSettings = this.api.getSettings()
    this.api.sendInspectorTree(INSPECTOR_ID)
    this.api.sendInspectorState(INSPECTOR_ID)
  }
  devtools.confirm = async function (message) {
    if (!this.app) return false
    return new Promise<boolean>((resolve) => {
      controller.confirm = {
        message,
        resolve,
      }
    })
  }
  devtools.getNumericSetting = function (key, defaultValue) {
    try {
      if (!this.api) return defaultValue
      return parseInt(this.api.getSettings()[key] ?? defaultValue)
    } catch {
      return defaultValue
    }
  }
  devtools.currentConfigWarnings = []
  devtools.configWarning = function (message) {
    this.currentConfigWarnings.push({ ...message, id: generateUUID() })
    this.refresh()
  }

  // setup modules
  setupPinia(devtools)
  setupApplicationNode(devtools)
  setupSessionNode(devtools)
  setupFormNode(devtools)
  setupGrpcNode(devtools)
  setupLuaNode(devtools)

  devtools.active = true

  createDevtoolsInstance(app)
}

export const devtoolsPlugin: Plugin = {
  install (app) {
    setupDevtools(app)
  },
}
