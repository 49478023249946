<template>
  <div id="devtools-app">
    <DevtoolsConfirm />
    <DevtoolsFAB />
    <DevtoolsWarnings />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import DevtoolsConfirm from './DevtoolsConfirm.vue'
import DevtoolsFAB from './DevtoolsFAB.vue'
import DevtoolsWarnings from './DevtoolsWarnings.vue'

export default defineComponent({
  name: 'DevtoolsApp',

  components: {
    DevtoolsConfirm,
    DevtoolsFAB,
    DevtoolsWarnings,
  },
})
</script>
